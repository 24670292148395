<template>
  <section class="gt-pagination">
    <el-pagination :class="className"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[6, 8, 20, 50, 100]"
      :page-size="pageSize"
      :layout="layout"
      :total="total"
      prev-text="上一页"
      next-text="下一页"
    >
    </el-pagination>
  </section>
</template>

<script>
export default {
  name:'gtPagination',
  props: {
    total: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    layout: {
      type: String,
      default: 'prev, pager, next',
    },
    className: {
      type: String,
      default: 'right',
    },
  },
  methods:{
    handleSizeChange(val) {
      let obj = {currentPage: 1, pageSize: val}
      this.$emit('updatePagination', obj)
    },
    handleCurrentChange(val) {
      let obj = {currentPage: val, pageSize: this.pageSize}
      this.$emit('updatePagination', obj)
    },
  }
}
</script>
<style lang="scss">
.gt-pagination{
    padding-top: 1px;;
    .el-pagination{
        font-weight: 400;
        margin-top: 20px;
        &.right{
            text-align: right;
        }
        &.center{
            text-align: center;
        }
        .btn-prev,
        .btn-next{
            min-width: 70px;
            background-color: $lv;
            color: #fff;
            &:disabled{
                color: #3333;
                background: #F2F2F2;
            }
        }
    }
}

</style>

