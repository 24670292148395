<template>
    <div class="contactUs">
        <div class="swiper-container pageTop_box" id="contactUsTop_swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,i) in banner" :key="i">
                    <div class="bg_img" :style="'background-image: url('+item+')'"></div>
                </div>
            </div>
            <div class="pagination contactUsTop-pagination"></div>
        </div>
        
        <div id="contactUs_1"></div>
        <!-- 商务合作 -->
        <div class="contactUs_2_box">
            <div class="box_ home_box_title">
                <span>商务合作</span>
                <div class="english absol_">BUSINESS COOPERATION</div>
            </div>
            <div class="box_ tab_list">
                <div class="text_line_middle" :class="{'active':selectTabId==item.id}" @click="selectTabId=item.id"
                v-for="item in cate_list" :key="item.id">
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="form_box">
                <div class="box_">
                    <div class="left_">
                        <div class="info">请务必完善信息，我们将立即尽快安排客户经理与您联系</div>
                        <el-form ref="form" label-position="left" :model="form" :rules="rules" label-width="118px">
                            <el-form-item label="城市：" prop="city">
                                <el-input v-model.trim="form.city" placeholder="请输入您想要合作的城市"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人：" prop="name">
                                <el-input v-model.trim="form.name" placeholder="请输入联系人姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="联系人电话：" prop="phone">
                                <el-input v-model.trim="form.phone" placeholder="请输入联系人电话" :maxlength='11'></el-input>
                            </el-form-item>
                            <el-form-item label="联系人邮箱：" prop="email">
                                <el-input v-model.trim="form.email" placeholder="请输入联系人邮箱"></el-input>
                            </el-form-item>
                            <el-form-item label="单位/机构名称：" prop="remarks">
                                <el-input v-model="form.remarks" placeholder="请输入单位机构名称"></el-input>
                            </el-form-item>

                            <el-form-item label-width="0">
                                <el-button class="btn_" @click="onSubmit()" :loading="isLoading">提交</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="right_" :style="{opacity: selectTab_city.length>0?1:0}">
                        <div class="lineLeft_box_title">已合作城市</div>
                        <div class="list_box scrollbar_box">
                            <div v-for="item in selectTab_city" :key="item.id" :style="'background-image: url('+item.thumbnail+')'">
                                <span>{{item.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="contactUs_2"></div>
        <!-- 加入我们 -->
        <div class="box_ contactUs_3_box">
            <div class="home_box_title">
                <span>{{joinUs.name||'加入我们'}}</span>
                <div class="english absol_">JOIN US</div>
            </div>
            <div class="info">
                {{joinUs.email_desc||'简历投递邮箱'}}：{{joinUs.email}}<br/>
                {{joinUs.title|edit_content}}
            </div>
            <div class="table_box">
                <el-form :inline="true" :model="form2" class="form_inline">
                    <el-form-item>
                        <el-select v-model="form2.address_id" placeholder="选择工作地点" clearable>
                            <el-option
                            v-for="item in cityList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item >
                        <el-select v-model="form2.type" placeholder="选择职位类别" clearable>
                            <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="form2.company_id" placeholder="选择分公司" clearable>
                            <el-option
                            v-for="item in companyList"
                            :key="item.id"
                            :label="item.company_name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item style="margin-right:0">
                        <el-input v-model="form2.key" placeholder="关键词"></el-input>
                    </el-form-item> -->
                    <el-form-item><el-button class="search_" type="primary" @click="search()">搜索</el-button></el-form-item>
                </el-form>

                <div class="table_">
                    <el-table
                    :data="tableList"
                    style="width: 100%">
                        <el-table-column
                            prop="company_name"
                            label="公司">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="职位名称">
                        </el-table-column>
                        <el-table-column
                            prop="detail_address"
                            label="工作地点"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="worked_year"
                            label="工作年限"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="add_time_str"
                            label="发布时间"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            width="180">
                            <template slot-scope="scope">
                                <span class="detail_text" @click="onDetail(scope.row)">查看详情</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <gt-pagination :total="total" :currentPage="currentPage" :pageSize="pageSize" @updatePagination="pagination_event"></gt-pagination>
            </div>
        </div>

        <div id="contactUs_3"></div>
        <!-- 联系我们     -->
        <div class="box_ contactUs_4_box">
            <div class="home_box_title">
                <span>联系我们</span>
                <div class="english absol_">CONTACT US</div>
            </div>

            <div class="content_">
                <div class="left_">
                    <div class="name">易宝产发集团总部</div>
                    <div class="line"></div>
                    <div class="info">地址：{{footerInfo.address}}</div>
                    <div class="info">电话：{{footerInfo.phone}}</div>
                    <div class="info">邮箱：{{footerInfo.email}}</div>
                    <img :src="footerInfo.erCode" class="ercode">
                </div>
                <div class="right_">
                    <a :href="'http://api.map.baidu.com/geocoder?address='+footerInfo.address+'&output=html'" target='_blank' class="map"/>
                </div>
            </div>

            <div class="list_tap" v-if="address_list.length>0">
                <div v-for="item in address_list" :key="item.id">
                    <div class="name">{{item.company_name}}</div>
                    <div class="line"></div>
                    <div class="info">地址：{{item.detail_address}}</div>
                    <div class="info">电话：{{item.phone}}</div>
                    <div class="info">邮箱：{{item.email}}</div>
                </div>
            </div>
        </div>


        <el-dialog
        :visible.sync="dialogVisible"
        :show-close="false"
        width="860px" custom-class="dialog_box detail_dialog">
             <img class="close_icon" src="../assets/img/close.png" @click="dialogVisible=false">   

             <div class="name">{{dialogDesc.name}}</div>    
             <div class="info">
                 <span>{{dialogDesc.company_name}}  |  {{dialogDesc.detail_address}}  |  {{dialogDesc.worked_year}}</span>
                 <span>发布时间：{{dialogDesc.add_time_str}}6</span>
             </div>
             <div class="desc html-content" v-html="dialogDesc.desc"></div>
             <el-button type="primary" class="btn_" @click="dialogVisible2=true">申请职位</el-button>
        </el-dialog>

        <el-dialog
        :visible.sync="dialogVisible2"
        :show-close="false"
        top="25vh"
        width="465px" custom-class="dialog_box sure_dialog">
            <img class="close_icon" src="../assets/img/close.png" @click="dialogVisible2=false">   
            <div class="desc">请将您的简历发送至：<span>{{joinUs.email}}</span></div>
            <el-button type="primary" class="btn_" @click="dialogVisible2=false">确定</el-button>
        </el-dialog>
        <footerComponent style="margin-top:70px"></footerComponent>
    </div>
</template>
<script>
import gtPagination from '@/components/gt-pagination'
import {getLocalTime} from '@/util/index'
export default {
    components:{gtPagination},
    name:'contactUs',
    data(){
        var validateMobile = (rule, value, callback) => {
            if (/^1[0-9]{10}$/.test(value)) {
               callback()
            } else {
               callback(new Error('联系人电话格式错误'))
            }
        }
        var validateEmail = (rule, value, callback) => {
            if (value && !/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(value)) {
                callback(new Error('邮箱格式错误'))
            } else {
                callback()
            }
        }
        return{
            banner:[],
            address_list:[],
            cate_list:[],
            selectTabId: null,
            selectTab_city:[],
            joinUs:{},

            form:{
                city:'',
                name:'',
                phone:'',
                email:'',
                remarks:''
            },
            isLoading:false,
            rules:{
                city: [
                    { required: true, message: '请输入您想要合作的城市', trigger: 'blur' }
                ], 
                phone: [
                    { required: true, message: '请输入联系人电话', trigger: 'blur' },
                    { validator: validateMobile, trigger: 'blur' }
                ], 
                remarks: [
                    { required: true, message: '请输入单位机构名称', trigger: 'blur' }
                ], 
                email:[
                    { validator: validateEmail, trigger: 'blur' }
                ]
            },
            form2:{
                address_id:null,
                type:null,
                company_id:null,
                // key:''
            },
            cityList:[],
            typeList:[],
            companyList:[],

            tableList:[],
            total:0,
            currentPage:1,
            pageSize: 8,

            dialogVisible: false,
            dialogDesc:{},
            dialogVisible2:false,
        }
    },
    computed:{
        footerInfo:{
            get(){
                return this.$store.state.footer||{}
            },
        },
    },
    watch:{
        selectTabId:{
            handler(val){
                this.$Request({
                    url: `/portal/get_cate_list/${val}/1`,
                    method: 'get',
                }).then(({data})=>{
                    let list = data&&data.articles;
                    this.selectTab_city = list;
                })
            }
        },
        $route:'scrollInto'
    },
    created(){
        this.initData()
        this.getList()
    },
    methods:{
        initData(){
            this.$Request({
                url:'/portal/selectWhere',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                this.cityList = data.city_list;
                this.typeList = data.type_list;
                this.companyList = data.company_list;
            })

            this.$Request({
                url: '/portal/link_us',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                //banner
                this.banner = this.$getListByObj(data.banner).map(item=>{return item.image});
                if(this.banner.length>1){
                    this.$nextTick(()=>{
                        new Swiper ('#contactUsTop_swiper', {
                            pagination: '.contactUsTop-pagination',
                            paginationClickable :true,
                            autoplay: 5000,
                        })
                    })
                }
                //商务合作
                let cate =  this.$initNes(data.cate||{})
                this.cate_list = cate.val;
                this.selectTabId = cate.val[0]&&cate.val[0].id
                //联系我们
                this.address_list =  data.address
                //加入我们
                let join_us =  this.$initNes(data.join_us||{}),
                    join_us_detail = join_us.val[0]||{}
                this.$set(this, 'joinUs',{
                    name: join_us.name,
                    email: join_us_detail.keywords,
                    email_desc: join_us_detail.title,
                    title: join_us_detail.content
                })
                this.scrollInto()
            }).catch(()=>{
                this.scrollInto()
            })
        },
        getList(){
            let param = {page: this.currentPage};
            if(this.form2.address_id)param.address_id = this.form2.address_id;
            if(this.form2.type)param.type = this.form2.type;
            if(this.form2.company_id)param.company_id = this.form2.company_id;
            this.$Request({
                url: '/portal/recruitmentList',
                method: 'get',
                data:param
            }).then(({data})=>{
                data = data||{};
                this.tableList = (data.content||[]).map(item=>{
                    item.add_time_str = getLocalTime(item.add_time,'yyyy.MM.dd')
                    return item
                });
            })
        },
        search(){
            this.currentPage = 1;
            this.getList()
        },
        onSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let param = Object.assign({tab: this.selectTabId}, this.form)
                    this.$Request({
                        url: '/portal/message',
                        method: 'get',
                        data: param
                    }).then(res=>{
                        if(res.code==1){
                            this.$message.success('提交成功');
                            this.$refs['form'].resetFields();
                        }else{
                            this.$message.error(res.msg||'提交失败')
                        }
                    })
                }
            });
        },
        onDetail(row){
            this.dialogVisible = true;
            this.dialogDesc = {...row}
        },
        pagination_event(val){
            this.$nextTick(() => {
                this.currentPage = val.currentPage
                this.pageSize = val.pageSize
                this.getList()
            })
        },
        scrollInto(anchor){
            this.$nextTick(()=>{
                if(this.$route.query&&this.$route.query.anchor){
                    let elId = 'contactUs_' + this.$route.query.anchor;
                    document.getElementById(elId).scrollIntoView(true);
                    return false;
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.contactUs{
    .pageTop_box{
        width: 100%;
        height: 450px;
    }
    .box_{
        width: 1200px;
        margin: 0 auto;
    }
    .contactUs_2_box{
        margin-top: 75px;
        .tab_list{
            margin-top: 40px;
            text-align: center;
            margin-bottom: 25px;
            >div{
                display: inline-block;
                width: 282px;
                height: 120px;
                color: #333;
                font-size: 20px;
                @include backImg('../assets/img/contactUs_2_g.png');
                cursor: pointer;
                &:not(:last-child){
                    margin-right: 24px;
                }
                &:hover{
                    color: $lv;
                }
                &.active{
                    color: #fff;
                    background-image: url('../assets/img/contactUs_2_b.png');
                }
            }
        }
        .form_box{
            background-color: rgba($color: #E6E6E6, $alpha: 0.8);
            padding: 66px 0;
            >div{
                background-color: #fff;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                padding-top: 30px;
                >div{
                    vertical-align: top;
                    display: inline-block;
                }
            }
            .left_{
               width: 588px; 
               padding: 0 77px 40px;
               .info{
                   margin-bottom: 16px;
                   font-size: 14px;
                   color: #333;
                   line-height: 21px;
               }
               .btn_{
                   width: 100%;
                   border-color: $lv;
                   color: $lv;
               }
            }
            .right_{
                width: calc(100% - 588px);
                padding-left: 100px;
                .list_box{
                    margin-top: 25px;
                    height: 335px;
                    overflow-y: auto;
                    >div{
                        display: inline-block;
                        vertical-align: middle;
                        width: 204px;
                        height: 147px;
                        margin-bottom: 20px;
                        position: relative;
                        @include backImg('../assets/img/home_6_1.png');
                        background-color: #ccc;
                        &:not(:nth-child(2n)){
                            margin-right: 25px;
                        }
                        >span{
                            position: absolute;
                            bottom: 4px;
                            left: 10px;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 21px;
                        }
                    }
                }
            }
        }
    }

    .contactUs_3_box{
        margin-top: 75px;
        .info{
            margin-top: 40px;
            font-size: 24px;
            color: #333333;
            line-height: 36px;
        }
        .table_box{
            margin-top: 40px;
            .search_{
                width: 120px;
            }
            .detail_text{
                font-size: 14px;
                color: #0090FF;
                cursor: pointer;
            }
        }
    }
    .contactUs_4_box{
        margin-top: 70px;
        .content_{
            height: 350px;
            >div{
                display: inline-block;
                vertical-align: top;
                height: 100%;
            }
            .left_{
                width: 384px;
                @include backImg('../assets/img/map_left.png');
                color: #fff;
                padding-left: 35px;
                padding-top: 35px;
                line-height: 21px;
                .name{
                    font-size: 18px;
                }
                .line{
                    margin: 15px 0 12px;
                    width: 48px;
                    height: 2px;
                    background:$lv;
                }
                .info{
                    font-size: 14px;
                    margin-top: 2px;
                }
                .ercode{
                    margin-top: 18px;
                    width: 124px;
                    height: 124px;
                }
            }
            .right_{
                width: calc(100% - 384px);
                @include backImg('../assets/img/test/map.png');
                >a{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
        .list_tap{
            margin-top: 20px;
            >div{
                display: inline-block;
                vertical-align: top;
                width: 385px;
                min-height: 146px;
                background: #F5F5F5;
                line-height: 21px;
                padding: 18px 14px;
                &:not(:nth-child(3n)){
                    margin-right: 20px;
                }
                .name{
                    font-size: 18px;
                }
                .line{
                    margin: 8px 0 6px;
                    width: 48px;
                    height: 2px;
                    background:$lv;
                }
                .info{
                    font-size: 14px;
                    margin-top: 2px;
                }
            }
        }
    }
    .dialog_box{
        .close_icon{
            position: absolute;
            right: 20px;
            top: 20px;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
    .detail_dialog{
        .name{
            font-size: 18px;
        }
        .info{
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
            padding-bottom: 14px;
            border-bottom: 1px solid #E6E6E6;
            &>span{
                display: inline-block;
                vertical-align: middle;
                &:first-of-type{
                    width:60%;
                }
                &:last-of-type{
                    width: 40%;
                    text-align: right;
                }
            }
        }
        .desc{
            font-size: 14px;
            margin-top: 35px;
            line-height: 21px;
        }
        .btn_{
           display: block; 
           margin : 50px auto 0;
           width: 240px;
           height: 42px;
           border-radius: 4px; 
        }
    }
    .sure_dialog{
       .desc{
           font-size: 14px;
           color: #000;
           >span{
               color: $lv;
           }
       } 
       .btn_{
           display: block; 
           margin : 45px auto 0;
           width: 240px;
           height: 42px;
           border-radius: 4px; 
        }
    }
}
</style>
<style lang="scss">
.contactUs_2_box{
    .form_box{
        .el-form-item{
            &.is-required {
                .el-form-item__label::before{
                    content: '';
                    margin-right: 0;
                }
                .el-input{
                    &::before{
                        content: '*必填';
                        color: #FF6565;
                        position: absolute;
                        right: 10px;
                    }
                }
            }
        }
        .el-form-item__label{
            padding-right: 0;
        }
    }
}
.contactUs_3_box{
    .form_inline{
        text-align: right;
        .el-select{
            width: 330px;
        }
        .el-form-item{
            margin-right: 28px;
            &:last-child{margin-right: 0;}
        }
    }
    .el-table{
        color: #333;
        .el-table__header{
            background: #E6E6E6;
            tr,th{
                background-color: initial!important;
            }
        }
        .el-table thead{
            color: #999999;
        }
    }

}
.el-dialog__header{
    padding:0;
}
.el-dialog__body {
    color: #333;
    padding: 0;
}
.dialog_box{
    padding: 70px 70px 40px;
    box-shadow: 0px 24px 95px 0px rgba(0, 0, 0, 0.34);
}
.detail_dialog{
    height: 633px;
    overflow-y: auto;
    border-radius: 8px;
    position: relative;
    &::-webkit-scrollbar-track {
        border-radius:5px;
        background-color:#fff
    }
    &::-webkit-scrollbar {
        width:5px;
        background-color:#fff
    }
    &::-webkit-scrollbar-thumb {
        border-radius:5px;
        height:200px;
        background:hsla(0,0%,84.7%,.3)
    }
    &::-webkit-scrollbar-thumb {
        background:#d8d8d8
    } 
}
.sure_dialog{
    height: 257px;
}
</style>